<template>
  <div class="StaffManage-list">
    <!-- <GlobalInfoBar title="活动海报" content="创建或管理换新活动海报" /> -->
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <StaffManageFrom />
      </div>
      <StaffManageTable />
    </GlobalChunk>
  </div>
</template>

<script >
export default {
  components: {
    StaffManageFrom: () => import("./from"),
    StaffManageTable: () => import("./table"),
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  methods: {},
};
</script>

<style scoped lang="scss" ref="stylesheet/scss">
.StaffManage-list {
  .button_top {
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .faultBtn {
    width: 280px;
    height: 42px;
    background: #f9fbfd;
    border: 1px solid #c1d1ff;
    box-shadow: 0px 4px 8px 0px rgba(196, 197, 198, 0.2);
    border-radius: 21px;
    display: flex;
    .left {
      text-align: center;
      color: white;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      height: 40px;
      background: #0981ff;
      border-radius: 20px;
    }
    .right {
      text-align: center;
      color: #333;
      width: 50%;
      height: 14px;
      font-size: 14px;
      font-family: FZLanTingHei-M-GBK;
      font-weight: 400;
      line-height: 40px;
      height: 40px;
      border-radius: 20px;
    }
  }
}
</style>
